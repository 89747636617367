export const path = {
	HOME: "/",
	HOMEPAGE: "/home",
	LOGIN: "/login",
	LOG_OUT: "/logout",
	SYSTEM: "/system",
	SIGNUP: "/sign-up",
};

export const languages = {
	VI: "vi",
	EN: "en",
};

export const manageActions = {
	ADD: "ADD",
	EDIT: "EDIT",
	DELETE: "DELETE",
};

export const dateFormat = {
	SEND_TO_SERVER: "DD/MM/YYYY",
};

export const YesNoObj = {
	YES: "Y",
	NO: "N",
};
